import PageBasic from './feature/page.basic/main';

export function loadFeatures()
{
    return {
        page: { basic: PageBasic }
    };
}

export function loadPlugins()
{
    return {
    };
}