class PageLoader {

    constructor(inpage = false) {
        this.inpage = inpage;
        this.reset();
    }

    reset() {
        this.meta = {};
        this.data = [];
        this.html = [];
        this.file = [];
    }

    loadElement(content) {
    }

    loadPage(source, index) {
        let item = null;
        for(const sourceitem of source)
        {
            if (sourceitem.index === index)
            {
                item = sourceitem;
                break;
            }
        }
        if (!item) return;
        if (item.parentIndex !== '') this.loadPage(source, item.parentIndex);
        for (const page of item.page) {
            const content = JSON.parse(page.content);
            this.loadElement(content);
            this.data.push(content);
        }
    }
}



export default PageLoader;
