import * as React from 'react';

import VenusBox from './Box';
import VenusImage from './Image';
import VenusMenu from './Menu';
import VenusText from './Text';

class VenusFragment extends React.Component {
    constructor(props) {
        super();
        this.elements = props.elements;
        this.globalelements = props.globalelements;
        this.width = props.width;
        this.grids = props.grids;
        this.direction = props.direction;
        this.gridwidth = props.width / props.grids;
        this.uuid = props.uuid;
    }

    drawElement(element, width, height, grids) {
        switch (element.type.toLowerCase()) {
            case 'box':
                return <VenusBox key={element.uuid} globalelements={this.globalelements} element={element} width={width} height={height} grids={grids} />;
            case 'image':
                return <VenusImage key={element.uuid} element={element} width={width} height={height} />;
            case 'menu':
                return <VenusMenu key={element.uuid} element={element} width={width} height={height} />;
            case 'text':
                return <VenusText key={element.uuid} element={element} width={width} height={height} />;
            default:
                return null;
        }
    }

    render() {
        const output = [];
        let line = [];
        let length = 0;
        let pixLength = 0;
        for (const element of this.elements)
        {
            if (element.size[0] + length > this.grids)
            {
                output.push(this.direction && this.direction.toLowerCase() === 'right' ? line.reverse() : line);
                length = 0;
                pixLength = 0;
                line = [];
            }
            const width = element.size[1] < 0 ? this.grids - length: Math.min(element.size[1], this.grids - length);
            const height = element.size.length > 2 ? element.size[2] : 0;
            const pixWidth = Math.floor((this.width - pixLength) / (this.grids - length) * width);
            const pixHeight = Math.floor(height * this.width / this.grids);
            length += width;
            pixLength += pixWidth;
            line.push(this.drawElement(element, pixWidth, pixHeight, width));           
        }
        for (const element of this.globalelements)
        {
            if (this.uuid !== element.puuid) continue;
            if (!element.size) continue;
            if (element.size[0] + length > this.grids)
            {
                output.push(this.direction && this.direction.toLowerCase() === 'right' ? line.reverse() : line);
                length = 0;
                pixLength = 0;
                line = [];
            }
            const width = element.size[1] < 0 ? this.grids - length: Math.min(element.size[1], this.grids - length);
            const height = element.size.length > 2 ? element.size[2] : 0;
            const pixWidth = Math.floor((this.width - pixLength) / (this.grids - length) * width);
            const pixHeight = Math.floor(height * this.width / this.grids);
            length += width;
            pixLength += pixWidth;
            line.push(this.drawElement(element, pixWidth, pixHeight, width));
        }
        if (length > 0) output.push(this.direction && this.direction.toLowerCase() === 'right' ? line.reverse() : line);
        return <React.Fragment>{output}</React.Fragment>;
    }
}

export default VenusFragment;