import * as React from 'react';

import Grid from '@mui/material/Grid';

import { load } from '../main/App';

class VenusImage extends React.Component {
    constructor(props) {
        super();
        this.element = props.element;
        this.width = props.width;
        this.height = props.height;
    }

    checkPage(link) {
        return link.search(new RegExp('^/[A-Za-z0-9_-]+.html$')) >= 0;
    }

    render() {
        if (!this.element.src) return null;

        let src = this.element.src;
        let href = this.element.href;
        let border = this.element.style ? this.element.style.border : null;
        let padding = this.element.style ? this.element.style.padding : null;
        let rounded = this.element.style ? this.element.style.rounded : null;

        if (!border) border = [0, 0, 0, 0, ''];
        if (!padding) padding = [0, 0, 0, 0];
        const width = this.width - border[2] - border[3] - padding[2] - padding[3];

        let style = {};
        style.width = width + 'px';
        if (this.height > 0) style.height = this.height + 'px';
        if (border[0] > 0) style.borderTop = border[0] + 'px solid' + border[4];
        if (border[1] > 0) style.borderBottom = border[1] + 'px solid' + border[4];
        if (border[2] > 0) style.borderLeft = border[2] + 'px solid' + border[4];
        if (border[3] > 0) style.borderRight = border[3] + 'px solid' + border[4];
        if (padding[0] > 0) style.paddingTop = padding[0] + 'px';
        if (padding[1] > 0) style.paddingBottom = padding[1] + 'px';
        if (padding[2] > 0) style.paddingLeft = padding[2] + 'px';
        if (padding[3] > 0) style.paddingRight = padding[3] + 'px';
        if (rounded) style.borderRadius = rounded + 'px';

        return <Grid key={this.element.uuid}>{href ? <a href={href} onClick={() => { if (this.checkPage(href)) { load(href.slice(0, -5)); return false; } else { return true; } }}><img alt={this.element.alt} src={src} style={style} /></a> : <img alt={this.element.alt} src={src} style={style} />}</Grid>;
    }
}

export default VenusImage;