import * as React from 'react';

import Grid from '@mui/material/Grid';

import { load } from '../main/App';

class VenusText extends React.Component {
    constructor(props) {
        super();
        this.element = props.element;
        this.width = props.width;
        this.height = props.height;

        let html = this.element.html;
        let selement = null;
        if (html) {
            selement = document.createElement('DIV');
            selement.innerHTML = html;
        }
        else {
            selement = document.getElementById('venus.' + this.element.uuid);
        }
        let items = selement.getElementsByTagName('A');
        for (let i = 0; i < items.length; i++) {
            items[i].className = this.element.uuid;
            if (this.checkPage(items[i].href))
                items[i].onclick = function () { load(items[i].href.slice(0, -5)); return false; };
        }
        this.element.html = selement.innerHTML;
    }

    checkPage(link) {
        return link.search(new RegExp('^/[A-Za-z0-9_-]+.html$')) >= 0;
    }

    render() {
        if (!this.element.html) return null;
        let halign = this.element.style ? this.element.style.halign : null;
        let valign = this.element.style ? this.element.style.valign : null;
        let size = this.element.style ? this.element.style.size : null;
        let color = this.element.style ? this.element.style.color : null;
        let font = this.element.style ? this.element.style.font : null;
        let bold = this.element.style ? this.element.style.bold : null;
        let italic = this.element.style ? this.element.style.italic : null;
        let border = this.element.style ? this.element.style.border : null;
        let padding = this.element.style ? this.element.style.padding : null;
        let rounded = this.element.style ? this.element.style.rounded : null;
        let linkColor = this.element.style ? this.element.style.linkColor : null;
        let linkDecoration = this.element.style ? this.element.style.linkDecoration : null;

        if (!border) border = [0, 0, 0, 0, ''];
        if (!padding) padding = [0, 0, 0, 0];
        if (!linkColor) linkColor = ['', ''];
        const width = this.width - border[2] - border[3] - padding[2] - padding[3];
        
        document.getElementById('venus.style').innerHTML += '.' + this.element.uuid + ' {color: ' + linkColor[0] + ';text-decoration: ' + (linkDecoration ? 'underline' : 'none') + ';}.' + this.element.uuid + ':hover {color: ' + linkColor[1] + ';}';

        if (!border) border = [0, 0, 0, 0, ''];
        if (!padding) padding = [0, 0, 0, 0];

        let style = {};
        let istyle = {width: "100%"};
        style.width = width + 'px';
        if (this.height > 0) style.height = this.height + 'px';
        if (halign === 'left') istyle.textAlign = 'left';
        if (halign === 'center') istyle.textAlign = 'center';
        if (halign === 'right') istyle.textAlign = 'right';
        if (valign === 'top') style.alignItems = 'flex-start';
        if (valign === 'center') style.alignItems = 'center';
        if (valign === 'bottom') style.alignItems = 'flex-end';
        if (size) style.fontSize = size + 'px';
        if (color) style.color = color;
        if (font) style.fontFamily = font;
        if (bold) style.fontWeight = 'bold';
        if (italic) style.fontStyle = 'italic';
        if (border[0] > 0) style.borderTop = border[0] + 'px solid' + border[4];
        if (border[1] > 0) style.borderBottom = border[1] + 'px solid' + border[4];
        if (border[2] > 0) style.borderLeft = border[2] + 'px solid' + border[4];
        if (border[3] > 0) style.borderRight = border[3] + 'px solid' + border[4];
        if (padding[0] > 0) style.paddingTop = padding[0] + 'px';
        if (padding[1] > 0) style.paddingBottom = padding[1] + 'px';
        if (padding[2] > 0) style.paddingLeft = padding[2] + 'px';
        if (padding[3] > 0) style.paddingRight = padding[3] + 'px';
        if (rounded) style.borderRadius = rounded + 'px';
        
        return <Grid key={this.element.uuid} item style={style} container><div style={istyle} dangerouslySetInnerHTML={{ __html: this.element.html }} /></Grid>;
    }
}

export default VenusText;