import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { load } from '../main/App';

class VenusMenu extends React.Component {
    constructor(props) {
        super();
        this.element = props.element;
        this.width = props.width;
        this.height = props.height;
        this.state = { open: -1, ref: null };

        let theme = this.element.style ? this.element.style.theme : null;
        this.theme = theme ? createTheme({
            palette: { primary: theme },
            components: {
                MuiList: {
                    styleOverrides: {
                        root: {
                            backgroundColor: theme.popupBackground,
                            color: theme.popupText,
                        },
                    },
                },
            },
        }) : null;
    }

    checkPage(link) {
        return link.search(new RegExp('^/[A-Za-z0-9_-]+.html$')) >= 0
    }

    render() {
        let variant = this.element.style ? this.element.style.variant : null;
        let halign = this.element.style ? this.element.style.halign : null;
        let valign = this.element.style ? this.element.style.valign : null;
        let padding = this.element.style ? this.element.style.padding : null;
        let esize = this.element.style ? this.element.style.esize : null;
        let ssize = this.element.style ? this.element.style.ssize : null;

        if (!padding) padding = [0, 0, 0, 0];

        let style = {};
        style.width = this.width + 'px';
        if (this.height > 0) style.height = this.height + 'px';
        if (halign === 'left') style.justifyContent = 'flex-start';
        if (halign === 'center') style.justifyContent = 'center';
        if (halign === 'right') style.justifyContent = 'flex-end';
        if (valign === 'top') style.alignItems = 'flex-start';
        if (valign === 'center') style.alignItems = 'center';
        if (valign === 'bottom') style.alignItems = 'flex-end';
        if (padding[0] > 0) style.paddingTop = padding[0] + 'px';
        if (padding[1] > 0) style.paddingBottom = padding[1] + 'px';
        if (padding[2] > 0) style.paddingLeft = padding[2] + 'px';
        if (padding[3] > 0) style.paddingRight = padding[3] + 'px';
        return <Grid key={this.element.uuid} container item style={style}>{this.width >= this.element.ethreshold ? this.drawStandard(this.element.data, variant, ssize) : this.drawEmbedded(this.element.data, esize)}</Grid>;
    }

    drawStandard(menu, variant, size) {
        let popup = [];
        let transparent = variant.toLowerCase() === 'transparent';
        let button = menu.map((element, i) => {
            let buttonStyle = { textTransform: 'none' };
            if (transparent) buttonStyle.backgroundColor = 'transparent';
            if (element.children) {
                popup.push(<Menu open={this.state.open === i} key={this.element.uuid + '_p_' + i} anchorEl={this.state.ref} elevation={0} onClose={() => { this.setState({ open: -1 }); }}>
                    {element.children.map((item, j) => {
                        if (!item.name)
                            return <Divider key={this.element.uuid + '_p_' + i + '_' + j} />;
                        return <MenuItem dense={true} key={this.element.uuid + '_p_' + i + '_' + j} onClick={(e) => { this.setState({ open: -1 }); this.triggleElement(item); }} disableRipple><ListItemText>{item.name}</ListItemText></MenuItem>;
                    })}
                </Menu>);
                return <Button disableElevation size={size} key={this.element.uuid + '_b_' + i} style={buttonStyle} onClick={(e) => { this.setState({ open: this.state.open === i ? -1 : i, ref: e.target }); }} endIcon={<ArrowDropDownIcon />}>{element.name}</Button>;
            }
            else
                return <Button disableElevation size={size} key={this.element.uuid + '_b_' + i} style={buttonStyle} onClick={() => { this.triggleElement(element); }}>{element.name}</Button>;
        });
        if (!transparent)
            button = <ButtonGroup variant={variant} size={size} disableElevation>{button}</ButtonGroup>;
        if (this.theme)
            return <ThemeProvider theme={this.theme}>{button}{popup}</ThemeProvider>;
        else
            return <React.Fragment>{button}{popup}</React.Fragment>;
    }

    drawEmbedded(menu, size) {
        let popup = <Menu open={this.state.open !== -1} anchorEl={this.state.ref} elevation={0} onClose={() => { this.setState({ open: -1 }); }}>{menu.map((element, i) => {
            if (element.children) {
                let items = [<MenuItem dense={true} key={this.element.uuid + '_p_' + i} onClick={() => { this.setState({ open: this.state.open === i ? -2 : i }); }} disableRipple><ListItemText>{element.name}</ListItemText><ListItemIcon>{this.state.open === i ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</ListItemIcon></MenuItem>];
                if (this.state.open === i)
                {  
                    element.children.forEach((item, j) => {
                        if (!item.name) return;
                        items.push(<MenuItem dense={true} key={this.element.uuid + '_p_' + i + '_' + j} style={{ paddingLeft: '30px' }} onClick={() => { this.setState({ open: -1 }); this.triggleElement(item); }} disableRipple><ListItemText>{item.name}</ListItemText></MenuItem>);
                    });
                }
                return items;
            }
            else
                return <MenuItem dense={true} key={this.element.uuid + '_b_' + i} onClick={() => { this.setState({ open: -1 }); this.triggleElement(element); }} disableRipple><ListItemText>{element.name}</ListItemText></MenuItem>;
        })}</Menu>;
        let button = <Button variant={'text'} size={size} onClick={(e) => { this.setState({ open: this.state.open !== -1 ? -1 : -2, ref: e.target }); }} disableElevation><MenuIcon fontSize={size} /></Button>;
        if (this.theme)
            return <ThemeProvider theme={this.theme}>{button}{popup}</ThemeProvider>;
        else
            return <React.Fragment>{button}{popup}</React.Fragment>;
    }

    triggleElement(element) {
        if (element.type === 'link') {
            if (this.checkPage(element.code))
                load(element.code.slice(0, -5));
            else
                window.location.href = element.code;
        }
        else
            eval(element.code);
    }
}

export default VenusMenu;