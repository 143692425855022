import * as React from 'react';

import Grid from '@mui/material/Grid';

import VenusFragment from './Fragment';

class Venus extends React.Component {
    constructor(props) {
        super();
        this.data = props.data;
        this.meta = props.meta;
        this.state = { width: window.innerWidth, height: window.innerHeight };
        if (this.meta) {
            if (this.meta.icon) {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                link.href = this.meta.icon;
            }
            if (this.meta.title)
                document.title = this.meta.title;
            if (this.meta.background)
            {
                const body = document.getElementsByTagName('BODY')[0];
                if (this.meta.background.backgroundImage) body.style.backgroundImage = 'url("' + CSS.escape(this.meta.background.backgroundImage) + '")';
                if (this.meta.background.backgroundPosition) body.style.backgroundPosition = this.meta.background.backgroundPosition;
                if (this.meta.background.backgroundRepeat) body.style.backgroundRepeat = this.meta.background.backgroundRepeat;
                if (this.meta.background.backgroundColor) body.style.backgroundColor = this.meta.background.backgroundColor;
                if (this.meta.background.backgroundSize) body.style.backgroundSize = this.meta.background.backgroundSize;
            }
        }
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        document.getElementById('venus.style').innerHTML = this.meta.style ? this.meta.style : '';
        const pageWidth = this.meta.pageWidth && this.meta.pageWidth < this.state.width ? this.meta.pageWidth : this.state.width; 
        const gridWidth = this.meta.gridWidth ? this.meta.gridWidth : 100;
        const grids = Math.floor(pageWidth / gridWidth);
        return <div align='center'><Grid container spacing={0} style={{ width: pageWidth }}><VenusFragment key={pageWidth} elements={[]} globalelements={this.data} width={pageWidth} grids={grids} direction={this.meta.direction} /></Grid></div>;
    }
}

export default Venus;